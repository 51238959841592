import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MarketService } from '../services/MarketService';
import DFMMobileHeader from '../components/DFMMobileHeader';

interface RecipeDetails {
  id: number;
  name: string;
  description: string | null;
  image_url: string | null;
  ingredients: {
    name: string;
    vendors: { name: string }[];
  }[];
  instructions: {
    step_number: number;
    instruction: string;
  }[];
}

const Recipe: React.FC = () => {
  const { recipeId } = useParams<{ recipeId: string }>();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState<RecipeDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [checkedIngredients, setCheckedIngredients] = useState<Set<number>>(new Set());

  useEffect(() => {
    const fetchRecipe = async () => {
      if (!recipeId) return;
      
      try {
        setLoading(true);
        const data = await MarketService.getRecipeDetails(parseInt(recipeId));
        setRecipe(data);
      } catch (err) {
        setError('Failed to load recipe details');
      } finally {
        setLoading(false);
      }
    };

    fetchRecipe();
  }, [recipeId]);

  const toggleIngredient = (index: number) => {
    const newChecked = new Set(checkedIngredients);
    if (newChecked.has(index)) {
      newChecked.delete(index);
    } else {
      newChecked.add(index);
    }
    setCheckedIngredients(newChecked);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#F8F4E3] flex items-center justify-center">
        <p className="text-[#4A3728]">Loading recipe...</p>
      </div>
    );
  }

  if (error || !recipe) {
    return (
      <div className="min-h-screen bg-[#F8F4E3] flex items-center justify-center">
        <p className="text-red-500">{error || 'Recipe not found'}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F8F4E3]">
      <DFMMobileHeader />
      <div className="lg:pt-0 pt-12">
        {/* Back Button - Mobile */}
        <button
          onClick={() => navigate(-1)}
          className="lg:hidden absolute top-16 left-4 z-10 bg-white/80 rounded-full p-2 shadow-md"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#2C5F2D]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        {/* Mobile Image */}
        <div className="lg:hidden w-full h-64 relative">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ 
              backgroundImage: `url(${recipe.image_url || '/assets/images/default-recipe.jpg'})`
            }}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-6">
            <h1 className="text-3xl font-bold text-white">{recipe.name}</h1>
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            {/* Content */}
            <div>
              {/* Back Button - Desktop */}
              <button
                onClick={() => navigate(-1)}
                className="hidden lg:flex items-center text-[#2C5F2D] hover:text-[#234C24] mb-4"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                Back to Market Day Recipes
              </button>

              {/* Desktop Title */}
              <h1 className="hidden lg:block text-4xl font-bold text-[#2C5F2D] mb-4">
                {recipe.name}
              </h1>

              {/* Description */}
              {recipe.description && (
                <p className="text-[#4A3728] mb-8">{recipe.description}</p>
              )}

              {/* Ingredients */}
              <div className="mb-8">
                <h2 className="text-2xl font-bold text-[#2C5F2D] mb-4">Ingredients</h2>
                <ul className="space-y-2">
                  {recipe.ingredients.map((ingredient, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <input
                        type="checkbox"
                        checked={checkedIngredients.has(index)}
                        onChange={() => toggleIngredient(index)}
                        className="mt-1 rounded border-[#7A918D] text-[#2C5F2D] focus:ring-[#2C5F2D]"
                      />
                      <div className={`flex flex-col transition-opacity duration-200 ${
                        checkedIngredients.has(index) ? 'opacity-40' : 'opacity-100'
                      }`}>
                        <span className="text-[#4A3728]">{ingredient.name}</span>
                        {ingredient.vendors.length > 0 && (
                          <span className="text-sm text-[#7A918D]">
                            Available at: {ingredient.vendors.map(v => v.name).join(', ')}
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Instructions */}
              <div>
                <h2 className="text-2xl font-bold text-[#2C5F2D] mb-4">Instructions</h2>
                <ol className="space-y-4 mb-24">
                  {recipe.instructions.map((instruction) => (
                    <li key={instruction.step_number} className="flex">
                      <span className="font-bold text-[#2C5F2D] mr-4">
                        {instruction.step_number}.
                      </span>
                      <span className="text-[#4A3728]">{instruction.instruction}</span>
                    </li>
                  ))}
                </ol>
              </div>
            </div>

            {/* Desktop Image */}
            <div className="hidden lg:block">
              <div 
                className="w-full h-[600px] rounded-lg bg-cover bg-center sticky top-20"
                style={{ 
                  backgroundImage: `url(${recipe.image_url || '/assets/images/default-recipe.jpg'})`
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipe; 