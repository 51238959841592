import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MarketService } from '../services/MarketService';
import DFMMobileHeader from '../components/DFMMobileHeader';

interface Recipe {
  id: number;
  name: string;
  description: string | null;
  image_url: string | null;
}

const MarketDayRecipes: React.FC = () => {
  const { date } = useParams<{ date: string }>();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      if (!date) return;
      
      try {
        setLoading(true);
        const data = await MarketService.getMarketDayRecipes(date);
        setRecipes(data);
      } catch (err) {
        setError('Failed to load recipes');
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, [date]);

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr + 'T00:00:00Z');
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  return (
    <div className="min-h-screen bg-[#F8F4E3]">
      <DFMMobileHeader />
      <div className="lg:pt-0 pt-12">
        <div className="bg-[#2C5F2D] text-[#F8F4E3] py-8">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold mb-2">Market Day Recipes</h1>
            {date && (
              <p className="text-lg opacity-90">{formatDate(date)}</p>
            )}
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          {loading ? (
            <div className="text-center">
              <p className="text-[#4A3728]">Loading recipes...</p>
            </div>
          ) : error ? (
            <div className="text-center">
              <p className="text-red-500">{error}</p>
            </div>
          ) : recipes.length === 0 ? (
            <div className="text-center">
              <p className="text-[#4A3728]">No recipes found for this market day.</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-24">
              {recipes.map((recipe) => (
                <div 
                  key={recipe.id}
                  className="relative aspect-square rounded-lg overflow-hidden group cursor-pointer"
                  onClick={() => navigate(`/dfm/recipe/${recipe.id}`)}
                >
                  {/* Image */}
                  <div 
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ 
                      backgroundImage: `url(${recipe.image_url || '/assets/images/default-recipe.jpg'})`
                    }}
                  />
                  
                  {/* Gradient Overlay */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent opacity-70 group-hover:opacity-90 transition-opacity duration-300" />
                  
                  {/* Text Content */}
                  <div className="absolute inset-0 p-6 flex flex-col justify-end text-white">
                    <h3 className="text-2xl font-bold mb-2 group-hover:mb-3 transition-all duration-300">
                      {recipe.name}
                    </h3>
                    {recipe.description && (
                      <p className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        {recipe.description}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketDayRecipes; 