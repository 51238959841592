import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DFMMobileHeader from '../components/DFMMobileHeader';
import { MarketService } from '../services/MarketService';

interface MarketDay {
  date: string;
  status: 'upcoming' | 'archived';
}

const DFM: React.FC = () => {
  const navigate = useNavigate();
  const [upcomingMarket, setUpcomingMarket] = useState<MarketDay | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUpcomingMarket = async () => {
      try {
        const market = await MarketService.getUpcomingMarket();
        setUpcomingMarket(market);
      } catch (err) {
        setError('Failed to load market information');
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingMarket();
  }, []);

  const formatMarketDate = (dateStr: string) => {
    const date = new Date(dateStr + 'T00:00:00Z');
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  return (
    <div className="min-h-screen bg-[#F8F4E3]">
      <DFMMobileHeader />
      {/* Changed padding breakpoint to match */}
      <div className="lg:pt-0 pt-12">
        {/* Header Section */}
        <div className="bg-[#2C5F2D] text-[#F8F4E3] py-8">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold mb-2">Davidson Farmer's Market</h1>
            <p className="text-lg opacity-90">Fresh, local ingredients for seasonal recipes</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-8">
          {/* Upcoming Market Section */}
          <section className="mb-12">
            <h2 className="text-2xl font-bold text-[#2C5F2D] mb-6">Upcoming Market</h2>
            {loading ? (
              <div className="bg-white rounded-lg shadow-lg p-6">
                <p className="text-[#4A3728]">Loading market information...</p>
              </div>
            ) : error ? (
              <div className="bg-white rounded-lg shadow-lg p-6">
                <p className="text-red-500">{error}</p>
              </div>
            ) : upcomingMarket ? (
              <div 
                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                onClick={() => navigate(`/dfm/market/${upcomingMarket.date}`)}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-xl font-semibold text-[#2C5F2D]">
                      {formatMarketDate(upcomingMarket.date)}
                    </h3>
                    <p className="text-[#4A3728] mt-2">
                      Click to view available ingredients and recipes
                    </p>
                  </div>
                  <div className="text-[#2C5F2D]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow-lg p-6">
                <p className="text-[#4A3728]">No upcoming markets scheduled at this time. Please check back soon!</p>
              </div>
            )}
          </section>

          {/* Past Markets Section */}
          <section>
            <h2 className="text-2xl font-bold text-[#2C5F2D] mb-6">Past Markets</h2>
            <button
              onClick={() => navigate('/dfm/past-markets')}
              className="bg-[#7A918D] text-white py-3 px-6 rounded-lg hover:bg-[#647371] transition-colors duration-300"
            >
              Browse Past Market Recipes
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DFM; 