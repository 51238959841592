import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketService } from '../services/MarketService';
import DFMMobileHeader from '../components/DFMMobileHeader';

interface ArchivedMarket {
  date: string;
  recipes: {
    id: number;
    name: string;
    description: string | null;
    image_url: string | null;
  }[];
}

const ArchivedMarkets: React.FC = () => {
  const navigate = useNavigate();
  const [markets, setMarkets] = useState<ArchivedMarket[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArchivedMarkets = async () => {
      try {
        setLoading(true);
        const data = await MarketService.getArchivedMarkets();
        setMarkets(data);
      } catch (err) {
        setError('Failed to load archived markets');
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedMarkets();
  }, []);

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr + 'T00:00:00Z');
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  return (
    <div className="min-h-screen bg-[#F8F4E3]">
      <DFMMobileHeader />
      <div className="lg:pt-0 pt-12">
        {/* Header */}
        <div className="bg-[#2C5F2D] text-[#F8F4E3] py-8">
          <div className="container mx-auto px-4">
            <button
              onClick={() => navigate('/dfm')}
              className="flex items-center text-[#F8F4E3] hover:text-[#F2E8CF] mb-4"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
              Back to Market Home
            </button>
            <h1 className="text-4xl font-bold">Past Market Recipes</h1>
          </div>
        </div>

        {/* Content */}
        <div className="container mx-auto px-4 py-8">
          {loading ? (
            <div className="text-center">
              <p className="text-[#4A3728]">Loading archived markets...</p>
            </div>
          ) : error ? (
            <div className="text-center">
              <p className="text-red-500">{error}</p>
            </div>
          ) : markets.length === 0 ? (
            <div className="text-center">
              <p className="text-[#4A3728]">No archived markets found.</p>
            </div>
          ) : (
            <div className="space-y-12">
              {markets.map((market, marketIndex) => (
                <section key={market.date} className="space-y-6">
                  {/* Market Date Header */}
                  <h2 className="text-2xl font-bold text-[#2C5F2D] border-b-2 border-[#2C5F2D] pb-2">
                    {formatDate(market.date)}
                  </h2>

                  {/* Recipe Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-24">
                    {market.recipes.map((recipe) => (
                      <div 
                        key={recipe.id}
                        className="relative aspect-square rounded-lg overflow-hidden group cursor-pointer"
                        onClick={() => navigate(`/dfm/recipe/${recipe.id}`)}
                      >
                        {/* Image */}
                        <div 
                          className="absolute inset-0 bg-cover bg-center"
                          style={{ 
                            backgroundImage: `url(${recipe.image_url || '/assets/images/default-recipe.jpg'})`
                          }}
                        />
                        
                        {/* Gradient Overlay */}
                        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent opacity-70 group-hover:opacity-90 transition-opacity duration-300" />
                        
                        {/* Text Content */}
                        <div className="absolute inset-0 p-6 flex flex-col justify-end text-white">
                          <h3 className="text-2xl font-bold mb-2 group-hover:mb-3 transition-all duration-300">
                            {recipe.name}
                          </h3>
                          {recipe.description && (
                            <p className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              {recipe.description}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArchivedMarkets; 