import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import RecipesHome from './pages/RecipesHome';
import DFM from './pages/DFM';
import ChatRecipeView from './pages/ChatRecipeView';
import Header from './components/Header';
import Footer from './components/Footer';
import MarketDayRecipes from './pages/MarketDayRecipes';
import Recipe from './pages/Recipe';
import ArchivedMarkets from './pages/ArchivedMarkets';

const AppContent: React.FC = () => {
  const location = useLocation();
  const isMainHome = location.pathname === '/';

  return (
    <div className="flex flex-col min-h-screen">
      {!isMainHome && <Header className="hidden md:block md:fixed md:top-0 md:left-0 md:right-0 md:z-50" />}
      <main className={`flex-grow ${!isMainHome ? 'md:mt-12' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recipes" element={<RecipesHome />} />
          <Route path="/dfm" element={<DFM />} />
          <Route path="/dfm/market/:date" element={<MarketDayRecipes />} />
          <Route path="/dfm/past-markets" element={<ArchivedMarkets />} />
          <Route path="/dfm/recipe/:recipeId" element={<Recipe />} />
          <Route path="/chat/:county" element={<ChatRecipeView />} />
        </Routes>
      </main>
      {!isMainHome && <Footer className="hidden md:block md:fixed md:bottom-0 md:left-0 md:right-0 md:z-50" />}
    </div>
  );
};

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;