import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DFMMobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="lg:hidden">
      {/* Fixed header bar */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-[#2C5F2D] text-[#FFF8E7] h-12 flex items-center justify-between px-4">
        <Link to="/" className="font-normal text-2xl font-['Italianno',_cursive] tracking-wide">
          Easy Seasonal Food
        </Link>
        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="p-2"
          aria-label="Menu"
        >
          {/* Hamburger icon */}
          <div className="w-6 h-5 relative flex flex-col justify-between">
            <span className={`w-full h-0.5 bg-[#FFF8E7] transition-transform duration-300 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`w-full h-0.5 bg-[#FFF8E7] transition-opacity duration-300 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`w-full h-0.5 bg-[#FFF8E7] transition-transform duration-300 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>

      {/* Slide-out menu */}
      <div 
        className={`fixed top-12 left-0 right-0 z-40 bg-[#2C5F2D] text-[#FFF8E7] transition-transform duration-300 ${
          isMenuOpen ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <nav className="flex flex-col">
          <Link 
            to="/" 
            className="px-4 py-3 hover:bg-[#234C24] transition-colors duration-200"
            onClick={() => setIsMenuOpen(false)}
          >
            Home
          </Link>
          <a 
            href="https://www.resilientfoodways.com/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="px-4 py-3 hover:bg-[#234C24] transition-colors duration-200"
            onClick={() => setIsMenuOpen(false)}
          >
            Read the Manifesto
          </a>
          <a 
            href="https://www.resilientfoodways.com/the-work" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="px-4 py-3 hover:bg-[#234C24] transition-colors duration-200"
            onClick={() => setIsMenuOpen(false)}
          >
            More ways AI can help local food systems
          </a>
          <a 
            href="mailto:greg@resilientfoodways.com" 
            className="px-4 py-3 hover:bg-[#234C24] transition-colors duration-200"
            onClick={() => setIsMenuOpen(false)}
          >
            Contact
          </a>
        </nav>
      </div>
    </div>
  );
};

export default DFMMobileHeader; 