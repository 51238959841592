import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#F8F4E3] via-[#F2E8CF] to-[#E8DFC5] p-8">
      <h1 className="text-5xl font-['Italianno',_cursive] mb-12 text-[#2C5F2D]">Easy Seasonal Food</h1>
      <div className="flex flex-col md:flex-row gap-8 max-w-4xl w-full justify-center">
        <button
          onClick={() => navigate('/dfm')}
          className="w-full md:w-96 p-8 bg-[#2C5F2D] text-[#F8F4E3] rounded-lg shadow-lg hover:bg-[#234C24] transition-all duration-300 transform hover:scale-105"
        >
          <h2 className="text-2xl font-bold mb-2">Davidson Farmer's Market</h2>
          <p className="text-sm opacity-80">Explore recipes available at Davidson Farmer's Market Right Now!</p>
        </button>
        
        <button
          onClick={() => navigate('/recipes')}
          className="w-full md:w-96 p-8 bg-[#2C5F2D] text-[#F8F4E3] rounded-lg shadow-lg hover:bg-[#234C24] transition-all duration-300 transform hover:scale-105"
        >
          <h2 className="text-2xl font-bold mb-2">Seasonal Recipes</h2>
          <p className="text-sm opacity-80">Find recipes using seasonal ingredients in your area</p>
        </button>
      </div>
    </div>
  );
};

export default Home;