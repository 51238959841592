import axios from 'axios';
import { API_BASE_URL } from '../config';

interface MarketDay {
  date: string;
  status: 'upcoming' | 'archived';
}

interface MarketRecipe {
  id: number;
  name: string;
  description: string | null;
  image_url: string | null;
}

interface RecipeVendor {
  name: string;
}

interface RecipeIngredient {
  name: string;
  vendors: RecipeVendor[];
}

interface RecipeInstruction {
  step_number: number;
  instruction: string;
}

interface RecipeDetails {
  id: number;
  name: string;
  description: string | null;
  image_url: string | null;
  ingredients: RecipeIngredient[];
  instructions: RecipeInstruction[];
}

interface ArchivedMarket {
  date: string;
  recipes: {
    id: number;
    name: string;
    description: string | null;
    image_url: string | null;
  }[];
}

export const MarketService = {
  getUpcomingMarket: async (): Promise<MarketDay | null> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/market/upcoming-market`);
      return response.data;
    } catch (error) {
      console.error('Error fetching upcoming market:', error);
      throw error;
    }
  },

  getMarketDayRecipes: async (date: string): Promise<MarketRecipe[]> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/market/day/${date}/recipes`);
      return response.data.recipes;
    } catch (error) {
      console.error('Error fetching market day recipes:', error);
      throw error;
    }
  },

  getRecipeDetails: async (recipeId: number): Promise<RecipeDetails> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/market/recipes/${recipeId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching recipe details:', error);
      throw error;
    }
  },

  getArchivedMarkets: async (): Promise<ArchivedMarket[]> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/market/archived-markets`);
      return response.data.markets;
    } catch (error) {
      console.error('Error fetching archived markets:', error);
      throw error;
    }
  }
}; 