import React from 'react';
import { useNavigate } from 'react-router-dom';
import CountyInput from '../components/CountyInput';

const RecipesHome: React.FC = () => {
  const navigate = useNavigate();

  const handleCountySubmit = (county: string) => {
    navigate(`/chat/${encodeURIComponent(county)}`);
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 flex items-center justify-center bg-gradient-to-br from-[#F8F4E3] via-[#F2E8CF] to-[#E8DFC5] p-8 md:p-16">
        <div className="max-w-md w-full">
          <h1 className="text-4xl font-bold mb-6 text-center text-[#2C5F2D]">Easy Seasonal Food</h1>
          <p className="text-lg mb-8 text-center text-[#4A3728]">Find recipes using fresh, in-season ingredients from your area</p>
          <CountyInput onSubmit={handleCountySubmit} />
        </div>
      </div>
      <div className="w-full md:w-1/2 bg-[#FFF8E7] relative">
        <div 
          className="absolute inset-0 bg-cover bg-center opacity-25 hidden md:block"
          style={{ 
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/grapes.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        ></div>
        <div className="relative z-10 p-8 md:p-16 flex items-center justify-center">
          <div className="max-w-md text-center">
            <h2 className="text-3xl font-bold mb-4 text-[#2C5F2D]">Nourish Your Community and Planet</h2>
            <p className="text-lg text-[#4A3728] mb-4">
              Choosing local, seasonal ingredients isn't just about preparing delicious meals – it's about making a positive impact on your health, community, and the environment.
            </p>
            <p className="text-lg text-[#4A3728] mb-4">
              Discover dishes that celebrate the bounty of your region, reduce food miles, and support local farmers. The smart recipe finder helps you make the most of what's in season right now.
            </p>
            <p className="text-lg text-[#4A3728]">
              Easily customize recipes to your taste, dietary needs, or available ingredients. The intelligent system adapts recipes in real-time, ensuring you always have the perfect dish for any occasion.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipesHome;